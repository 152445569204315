import React from "react";
import Reset from "../../styles/reset";
import Home from "../../pages/home/home";
import { Route, Routes } from "react-router-dom";
import ComingSoonPage from "../../pages/coming-sson/coming-soon";

export default function App() {
  return (
    <>
      <Reset />
      <Routes>
        {/* <Route path="/" element={<ComingSoonPage />} /> */}
        {/* <Route path="/kmzhuvcrd5gtvh7ks8" element={<Home/>}/> */}
        <Route path="/" element={<Home/>}/>
      </Routes>
    </>
  );
}
