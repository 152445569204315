import styled from "styled-components";
import vars from "../../styles/vars";

type HeaderWrapperProps = {
  backgroundColor: string;
};

export const HeaderWrapper = styled.header<HeaderWrapperProps>`
  position: relative;
  overflow: hidden;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#E04D25"};
`;

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 100%;
  display: grid;
  grid-template-columns: 0.9fr 1fr;

  @media (max-width: 1300px) {
    max-width: 1200px;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    max-width: 900px;
  }

  @media (max-width: 1000px) {
    max-width: 850px;
  }

  @media (max-width: 900px) {
    max-width: 780px;
    padding-bottom: 1rem;
  }

  @media (max-width: 850px) {
    max-width: 720px;
  }

  @media (max-width: 740px) {
    max-width: 600px;
  }

  @media (max-width: 510px) {
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1441px) {
    max-width: 100%;
  }
`;

export const HeaderContainerLeft = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1.3fr 1fr;
  padding-bottom: 2rem;

  @media (max-width: 1300px) {
    padding-bottom: 0rem;
  }

  @media (max-width: 1030px) {
    grid-template-rows: auto;
  }

  @media (max-width: 510px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const HeaderContainerRight = styled.div`
  display: flex;
  margin-right: 5rem;

  @media (max-width: 1300px) {
    margin-right: 1rem;
  }

  @media (max-width: 1100px) {
    margin-right: 0rem;
  }
`;

export const HeaderHeading = styled.div`
  background-image: url("/assets/images/h1-background.png");
  background-size: cover;
  background-position: 20% 100%;
  background-repeat: no-repeat;

  display: flex;
  justify-content: flex-end;
`;

export const HeaderHeadingContent = styled.div`
  width: 620px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  padding: 1rem 2rem 4.5rem 2rem;

  @media (max-width: 1300px) {
    width: 100%;
    max-width: 500px;
    gap: 2rem;
  }

  @media (max-width: 1030px) {
    max-width: 350px;
    padding: 1rem 2rem 2rem 2rem;
    gap: 0;
  }

  @media (max-width: 850px) {
    padding: 1.5rem;
  }

  @media (max-width: 740px) {
    justify-content: center;
    gap: 2rem;
    padding: 1rem;
    max-width: 250px;
  }

  @media (max-width: 510px) {
    max-width: 100%;
  }

  > img {
    width: 8.5rem;
    height: auto;

    @media (max-width: 740px) {
      width: 6rem;
    }

    @media (max-width: 510px) {
      align-self: flex-end;
      position: absolute;
      top: 40px;
      z-index: 20;
      width: 9rem;
    }

    @media (max-width: 400px) {
      width: 8rem;
    }
  }

  h1 {
    font-weight: 800;
    font-size: 6.5rem;
    line-height: 75%;
    text-align: left;
    letter-spacing: -2px;
    max-width: 50%;
    font-family: ${vars.fontPoppins};
    color: white;
    margin-left: 3rem;

    @media (max-width: 1300px) {
      font-size: 5.5rem;
      margin-left: 2rem;
    }

    @media (max-width: 1030px) {
      font-size: 4rem;
      margin-left: 1rem;
    }

    @media (max-width: 850px) {
      font-size: 3.5rem;
    }

    @media (max-width: 740px) {
      font-size: 3rem;
    }

    @media (max-width: 510px) {
      white-space: nowrap;
      padding-bottom: 1rem;
      margin-left: 0;
      padding-block: 1rem;
    }

    & p:last-child {
      color: #d1b7d8;
      font-weight: 300;
    }
  }
`;

export const HeaderArtistGroupBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;

  @media (max-width: 510px) {
    padding-top: 2rem;
  }

  > img {
    width: 585px;

    @media (max-width: 1300px) {
      width: 440px;
    }

    @media (max-width: 1030px) {
      width: 300px;
    }

    @media (max-width: 740px) {
      width: 180px;
    }

    @media (max-width: 510px) {
      width: 100%;
      display: none;
    }
  }
`;

export const HeaderArtistGroupBlockBottom = styled.div`
  display: flex;
  width: 585px;

  @media (max-width: 1300px) {
    width: 440px;
  }

  @media (max-width: 1030px) {
    width: 300px;
  }

  @media (max-width: 740px) {
    width: 180px;
  }

  @media (max-width: 510px) {
    width: 100%;
  }

  > div {
    position: relative;

    @media (max-width: 510px) {
      display: flex;
      align-items: stretch;
      justify-content: stretch;
    }
  }
`;

export const HeaderArtistGroupBlockBottomYellow = styled.div`
  @media (max-width: 1300px) {
    > img {
      width: 260px;
    }
  }

  @media (max-width: 1030px) {
    > img {
      width: 180px;
    }
  }

  @media (max-width: 740px) {
    > img {
      width: 120px;
    }
  }

  @media (max-width: 510px) {
    display: flex;
    align-items: flex-end;
    > img {
      width: 100%;
    }
  }
`;

export const HeaderArtistGroupBlockBottomGreen = styled.div`
  @media (max-width: 1300px) {
    > img {
      width: 180px;
    }
  }

  @media (max-width: 1030px) {
    > img {
      width: 120px;
    }
  }

  @media (max-width: 740px) {
    > img {
      width: 60px;
    }
  }

  @media (max-width: 510px) {
    display: flex;
    align-items: flex-end;
    > img {
      width: 100%;
    }
  }
`;

export const Artist1 = styled.div`
  position: absolute;
  bottom: -1px;
  left: -42px;

  @media (max-width: 1300px) {
    bottom: 7px;
    left: -30px;

    img {
      width: 330px;
    }
  }

  @media (max-width: 1030px) {
    bottom: 0px;
    left: -25px;

    img {
      width: 245px;
    }
  }

  @media (max-width: 740px) {
    bottom: -1px;
    left: -17px;

    img {
      width: 167px;
    }
  }

  @media (max-width: 510px) {
    bottom: -4px;
    left: -23px;

    img {
      width: 105%;
    }
  }

  @media (max-width: 400px) {
    bottom: -4px;
    left: -17px;

    img {
      width: 100%;
    }
  }
`;

export const HeaderContainerRightColOne = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    position: relative;
    width: 218px;
    display: flex;

    @media (max-width: 1300px) {
      width: 180px;
    }

    @media (max-width: 1030px) {
      width: 129px;
    }

    @media (max-width: 740px) {
      width: 100px;
    }

    @media (max-width: 510px) {
      width: 124px;
    }

    > img {
      width: 100%;
    }
  }
`;

export const Artist2 = styled.div`
  position: absolute;
  bottom: -7px;
  right: -4px;

  img {
    width: 100%;
  }
`;

export const Artist3 = styled.div`
  position: absolute;
  bottom: -12px;
  right: -6px;

  @media (max-width: 1030px) {
    bottom: -10px;
    right: -4px;
  }

  @media (max-width: 740px) {
    bottom: -9px;
    right: -3px;
  }

  @media (max-width: 510px) {
    bottom: -8px;
    right: -5px;
  }

  img {
    width: 231px;

    @media (max-width: 1300px) {
      width: 188px;
    }

    @media (max-width: 1030px) {
      width: 135px;
    }

    @media (max-width: 740px) {
      width: 104px;
    }

    @media (max-width: 510px) {
      width: 135px;
    }
  }
`;

export const HeaderContainerRightGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 1030px) {
    gap: 0.5rem;
  }

  @media (max-width: 510px) {
    width: 100%;
  }
`;

export const HeaderContainerRightColTwoAndThree = styled.div`
  display: grid;
  grid-template-columns: 218px 230px;

  @media (max-width: 1300px) {
    grid-template-columns: 180px 192px;
  }

  @media (max-width: 1030px) {
    grid-template-columns: 129px 138px;
  }

  @media (max-width: 740px) {
    grid-template-columns: 100px 115px;
  }

  @media (max-width: 510px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
`;

export const HeaderContainerRightColTwo = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 400px) {
    transform: translateY(-5px);
  }

  > div {
    position: relative;
    width: 218px;
    display: flex;

    @media (max-width: 1300px) {
      width: 180px;
    }

    @media (max-width: 1030px) {
      width: 129px;
    }

    @media (max-width: 740px) {
      width: 100px;
    }

    @media (max-width: 510px) {
      width: 100%;
    }

    @media (max-width: 400px) {
      width: 135px;
    }

    > img {
      width: 100%;
    }
  }
`;

export const Artist4 = styled.div`
  position: absolute;
  bottom: -6px;
  right: -10px;

  @media (max-width: 740px) {
    bottom: -5px;
    right: -3px;
  }

  img {
    width: 245px;

    @media (max-width: 1300px) {
      width: 195px;
    }

    @media (max-width: 1030px) {
      width: 135px;
    }

    @media (max-width: 740px) {
      width: 110px;
    }
  }
`;

export const HeaderContainerRightColThree = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    position: relative;
    width: 230px;
    display: flex;

    @media (max-width: 1300px) {
      width: 192px;
    }

    @media (max-width: 1030px) {
      width: 138px;
    }

    @media (max-width: 740px) {
      width: 115px;
    }

    @media (max-width: 510px) {
      width: 130px;
    }

    > img {
      width: 100%;
    }
  }
`;

export const Artist5 = styled.div`
  position: absolute;
  bottom: -7px;
  right: -1px;

  @media (max-width: 1030px) {
    right: 0px;
  }

  @media (max-width: 740px) {
    bottom: -6px;
  }

  @media (max-width: 510px) {
    bottom: -3px;
  }

  img {
    width: 231px;

    @media (max-width: 1300px) {
      width: 195px;
    }

    @media (max-width: 1030px) {
      width: 138px;
    }

    @media (max-width: 740px) {
      width: 115px;
    }

    @media (max-width: 510px) {
      width: 130px;
    }
  }
`;

export const Artist6 = styled.div`
  position: absolute;
  bottom: -8px;
  right: -10px;

  @media (max-width: 740px) {
    bottom: -6px;
    right: -7px;
  }

  img {
    width: 258px;

    @media (max-width: 1300px) {
      width: 214px;
    }

    @media (max-width: 1030px) {
      width: 158px;
    }

    @media (max-width: 740px) {
      width: 133px;
    }

    @media (max-width: 510px) {
      width: 150px;
    }
  }
`;

export const ParagraphContainer = styled.div`
  font-family: ${vars.fontSpaceMono};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  letter-spacing: 0.3px;
  line-height: 110%;
  padding-inline: 2rem;
  max-width: 500px;

  @media (max-width: 1300px) {
    font-size: 0.85rem;
    max-width: 400px;
  }

  @media (max-width: 1100px) {
    max-width: 100%;
    padding-inline: 1rem;
  }

  @media (max-width: 1030px) {
    font-size: 0.75rem;
    line-height: 120%;
    padding-bottom: 1rem;
    padding-right: 0;
  }

  @media (max-width: 900px) {
    position: absolute;
    font-size: 0.7rem;
    bottom: 10px;
    max-width: 320px;
  }

  @media (max-width: 790px) {
    max-width: 275px;
    bottom: 0px;
  }

  @media (max-width: 740px) {
    max-width: 680px;
    left: 0;
    padding-right: 0.5rem;
    font-size: 0.8rem;
    bottom: -10px;
  }

  @media (max-width: 510px) {
    max-width: 100%;
    position: static;
    font-size: 1.1rem;
    padding-bottom: 2rem;
  }
`;
