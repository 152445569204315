type Props = {
  [key: string]: any;
};

const vars: Props = {
  rootSize: 20,
  fontPoppins: `"Poppins", serif`,
  fontFraunces: `"Fraunces", serif`,
  fontKamino: `"Kamino", sans-serif`,
  fontKarla: `"Karla", sans-serif`,
  fontOswald: `"Oswald", sans-serif`,
  fontNanum: `"Nanum Gothic Coding", monospace`,
  fontRoboto: `"Roboto", sans-serif`,
  fontSpaceMono: `"Space Mono", monospace`,
  zIndex: {
    header: 2,
    background: 0,
    report: 2,
    reportPopUp: 3,
    splashScreen: 4,
    splashScreenContent: 5,
  },
  colors: {
    white: "#ffffff",
    black: "#000000",
    primary: "#213862",
    red: "#E2302E",
    green: "#00918D",
    yellow: "#F5A300",
  },
};

export default vars;
