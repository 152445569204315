import styled, { css } from "styled-components";
import vars from "../../styles/vars";

type CardWrapperProps = {
  $layout: string;
  $bgColor: string;
};

export const CardWrapper = styled.div<CardWrapperProps>`
  background-color: ${({ $bgColor }) => $bgColor && $bgColor};
  display: grid;
  grid-template-columns: 50% 50%;
  overflow: hidden;
  height: 625px;

  @media (max-width: 1200px) {
    height: 580px;
  }

  @media (max-width: 1000px) {
    height: 500px;
  }

  @media (max-width: 850px) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    height: 100%;
  }

  ${({ $layout }) => {
    if ($layout === "grid") {
      return css`
        grid-template-columns: 100%;
        grid-template-rows: 85% 15%;

        @media (max-width: 850px) {
          grid-template-rows: 85% 15%;
          min-height: 855px;
        }
      `;
    }
  }}
`;

type CardLeftProps = {
  $bgImage?: string;
  $bgImageLeftMobile?: string;
  $layout: string;
};

export const CardLeft = styled.div<CardLeftProps>`
  background-image: ${({ $bgImage }) => $bgImage && `url(${$bgImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 1000px) {
    background-size: ${({ $layout }) => $layout === "grid" && "contain"};
  }

  @media (max-width: 850px) {
    min-height: 750px;
  }

  @media (max-width: 500px) {
    min-height: 450px;
    background-image: ${({ $bgImageLeftMobile }) =>
      $bgImageLeftMobile && `url(${$bgImageLeftMobile})`};
  }

  & h2 {
    // for accessability of card left copy on image
    opacity: 0;
    font-size: 0.2rem;
  }
`;

type CardRightProps = {
  $bgImage?: string;
  $index?: number;
  $buttonColor: string;
  $buttonHoverColor: string;
  $buttonCopyColor: string;
  $layout: string;
  $color: string;
};

export const CardRight = styled.div<CardRightProps>`
  background-image: ${({ $bgImage }) => $bgImage && `url(${$bgImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  color: ${({ $color }) => $color};

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    height: 100%;
    padding: 3rem 2rem;

    & h3 {
      font-size: 0.9em;
      font-family: ${vars.fontSpaceMono};
      max-width: 22rem;
    }

    @media (max-width: 1200px) {
      padding: 2rem 1.5rem;
      gap: 1rem;
      align-items: stretch;
      font-size: 0.9em;

      & h3 {
        font-size: 0.95em;
      }
    }

    @media (max-width: 1200px) {
      padding: 2rem 1rem;

      & h3 {
        font-size: 0.85em;
      }
    }

    @media (max-width: 850px) {
      padding-top: 2em;

      & h3 {
        font-size: 1em;
        max-width: 100%;
      }
    }

    @media (max-width: 500px) {

      & h3 {
        font-size: 1.4em;
      }
    }

    ${({ $layout }) => {
      if ($layout === "grid") {
        return css`
          flex-direction: row;
          padding: 1rem;
          align-items: center;

          @media (max-width: 1200px) {
            padding: 1.3rem;
          }

          @media (max-width: 1000px) {
            padding: 1rem;
          }

          @media (max-width: 850px) {
            flex-direction: column;
          }
        `;
      }
    }}

    & p {
      font-size: 0.8em;
      font-weight: 300;
      letter-spacing: 0.3px;
      line-height: 140%;
      max-width: 90%;
      font-family: ${vars.fontRoboto};

      @media (max-width: 1000px) {
        font-size: 0.7em;
        max-width: 100%;
      }

      @media (max-width: 900px) {
        font-size: 0.6rem;
      }

      @media (max-width: 850px) {
        font-size: 0.9em;
      }

      @media (max-width: 500px) {
        font-size: 1.2em;
      }
    }

    & a {
      display: inline-block;
      background-color: ${({ $buttonColor }) =>
        $buttonColor ? $buttonColor : `${vars.colors.yellow}`};
      color: ${({ $buttonCopyColor }) =>
        $buttonCopyColor === "white"
          ? `${vars.colors.white}`
          : `${vars.colors.black}`};
      font-family: ${vars.fontPoppins};
      padding: 0.7em 1em;
      max-width: max-content;
      cursor: pointer;
      border: none;
      font-size: 0.8em;
      font-weight: 600;
      text-decoration: none;
      position: relative;
      transition: background-color 0.3s ease-in, color 0.3s ease-in;

      @media (max-width: 1000px) {
        font-size: 0.7rem;
      }

      @media (max-width: 900px) {
        font-size: 0.6rem;
      }

      @media (max-width: 800px) {
        font-size: 0.87rem;
        max-width: 100%;
        text-align: center;
      }

      @media (max-width: 500px) {
        font-size: 1rem;
      }

      &:disabled {
        cursor: not-allowed;
      }

      &:hover {
        background-color: ${({ $buttonHoverColor }) =>
          $buttonHoverColor ? $buttonHoverColor : `${vars.colors.primary}`};
        color: ${({ $buttonCopyColor }) =>
          $buttonCopyColor === "white"
            ? `${vars.colors.black}`
            : `${vars.colors.white}`};
      }

      &:disabled:hover {
        background-color: ${vars.colors.primary};
      }
    }
  }
`;
