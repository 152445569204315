import styled from "styled-components";
import vars from "../../styles/vars";

export const FooterWrapper = styled.footer`
  color: ${vars.colors.white};
  font-family: ${vars.fontRoboto};
  font-weight: 200;
  background-color: ${vars.colors.primary};
  padding: 3em 1em;
  margin-top: 2em;

  @media (max-width: 700px) {
    margin-top: 0;
    font-size: 1.3em;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Emails = styled.div`
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: 700px) {
    margin: 0;
    gap: 1rem;
  }
`;

export const Left = styled.div`
  & span {
    font-weight: 600;
  }

  small {
    font-size: 0.5em;

    & > a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & p {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
    }
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  & > img {
    width: 5em;
  }
`;

export const Socials = styled.div`
  display: flex;
  gap: 1rem;
  a {
    width: 1em;

    @media (max-width: 700px) {
      width: 1.3em;
    }
  }

  a:last-child {
    margin-left: 0.5em;
  }

  img {
    height: 1em;

    @media (max-width: 700px) {
      height: 1.3em;
    }
  }

  @media (max-width: 700px) {
    transform: translateY(-35px);
  }
`;
