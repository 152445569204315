import React from "react";
import { useInView } from "react-intersection-observer";
import {
  HeaderHeading,
  HeaderContainer,
  HeaderContainerLeft,
  HeaderContainerRight,
  HeaderArtistGroupBlock,
  HeaderWrapper,
  Artist1,
  HeaderArtistGroupBlockBottom,
  HeaderContainerRightColOne,
  HeaderContainerRightColTwo,
  HeaderContainerRightColTwoAndThree,
  HeaderContainerRightColThree,
  Artist2,
  Artist3,
  HeaderHeadingContent,
  Artist4,
  Artist5,
  Artist6,
  ParagraphContainer,
  HeaderContainerRightGrid,
  HeaderArtistGroupBlockBottomYellow,
  HeaderArtistGroupBlockBottomGreen,
} from "./styles";
import Animate from "../animate/animate";
import Content from "../content/content";

type ArtistT = {
  attributes: {
    url: string;
  };
};

type Props = {
  title: string;
  artists: {
    artist: { data: ArtistT[] };
  }[];
  content: string;
  backgroundColors: { color: string; id: string }[];
};

export default function Header({
  title,
  artists,
  content,
  backgroundColors,
}: Props) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <HeaderWrapper ref={ref} backgroundColor={backgroundColors?.[0]?.color}>
      <HeaderContainer>
        <HeaderContainerLeft>
          <HeaderHeading>
            <HeaderHeadingContent>
              <img src="/assets/logos/ifpi-logo-white.svg" alt="IFPI" />
              <Animate className="fade-up fade-up-7" isVisible={inView}>
                <h1>
                  <p dangerouslySetInnerHTML={{ __html: title }} />
                  <p>2025</p>
                </h1>
              </Animate>
            </HeaderHeadingContent>
          </HeaderHeading>
          <HeaderArtistGroupBlock>
            <img src={"/assets/images/group-top.png"} alt="" />
            <HeaderArtistGroupBlockBottom>
              <HeaderArtistGroupBlockBottomYellow>
                <img src={"/assets/images/group-yellow.png"} alt="" />
                <Artist1>
                  <Animate
                    className={`lazy-load lazy-load-${4 + 1}`}
                    isVisible={inView}
                  >
                    <img
                      src={artists?.[0].artist?.data?.[0].attributes.url}
                      alt=""
                    />
                  </Animate>
                </Artist1>
              </HeaderArtistGroupBlockBottomYellow>
              <HeaderArtistGroupBlockBottomGreen>
                <img src={"/assets/images/group-green.png"} alt="" />
              </HeaderArtistGroupBlockBottomGreen>
            </HeaderArtistGroupBlockBottom>
          </HeaderArtistGroupBlock>
        </HeaderContainerLeft>
        <HeaderContainerRight>
          <HeaderContainerRightColOne>
            <div>
              <img src={"/assets/images/col-1-el-1.png"} alt="" />
              <Artist2>
                <Animate
                  className={`lazy-load lazy-load-${3 + 1}`}
                  isVisible={inView}
                >
                  <img
                    src={artists?.[2].artist?.data?.[0].attributes.url}
                    alt=""
                  />
                </Animate>
              </Artist2>
            </div>
            <div>
              <img src={"/assets/images/col-1-el-2.png"} alt="" />
            </div>
            <div>
              <img src={"/assets/images/col-1-el-3.png"} alt="" />
              <Artist3>
                <Animate
                  className={`lazy-load lazy-load-${0 + 1}`}
                  isVisible={inView}
                >
                  <img
                    src={artists?.[1].artist?.data?.[0].attributes.url}
                    alt=""
                  />
                </Animate>
              </Artist3>
            </div>
            <div>
              <img src={"/assets/images/col-1-el-4.png"} alt="" />
            </div>
          </HeaderContainerRightColOne>
          <HeaderContainerRightGrid>
            <HeaderContainerRightColTwoAndThree>
              <HeaderContainerRightColTwo>
                <div>
                  <img src={"/assets/images/col-2-el-1.png"} alt="" />
                  <Artist4>
                    <Animate
                      className={`lazy-load lazy-load-${5 + 1}`}
                      isVisible={inView}
                    >
                      <img
                        src={artists?.[3].artist?.data?.[0].attributes.url}
                        alt=""
                      />
                    </Animate>
                  </Artist4>
                </div>
                <div>
                  <img src={"/assets/images/col-2-el-2.png"} alt="" />
                </div>
              </HeaderContainerRightColTwo>
              <HeaderContainerRightColThree>
                <div>
                  <img src={"/assets/images/col-3-el-1.png"} alt="" />
                  <Artist5>
                    <Animate
                      className={`lazy-load lazy-load-${1 + 1}`}
                      isVisible={inView}
                    >
                      <img
                        src={artists?.[4].artist?.data?.[0].attributes.url}
                        alt=""
                      />
                    </Animate>
                  </Artist5>
                </div>
                <div>
                  <img src={"/assets/images/col-3-el-2.png"} alt="" />
                  <Artist6>
                    <Animate
                      className={`lazy-load lazy-load-${2 + 1}`}
                      isVisible={inView}
                    >
                      <img
                        src={artists?.[5].artist?.data?.[0].attributes.url}
                        alt=""
                      />
                    </Animate>
                  </Artist6>
                </div>
              </HeaderContainerRightColThree>
            </HeaderContainerRightColTwoAndThree>
            <ParagraphContainer>
              <Animate className="fade-up fade-up-3" isVisible={inView}>
                <Content content={`<p>${content}</p>`} />
              </Animate>
            </ParagraphContainer>
          </HeaderContainerRightGrid>
        </HeaderContainerRight>
      </HeaderContainer>
    </HeaderWrapper>
  );
}
